import { Box } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoadingIndicator, useEnv } from '..';
import { QueryParamProvider } from 'use-query-params';
import { RouteAdaptor } from './RouteAdaptor';
import CreateLocation from 'src/modules/locations/create-location';
import CreateUser from 'src/modules/users/create-user';
import NotFoundContainer from 'src/modules/not-found';
import { tokenUserPermissions } from 'src/lib/constants/token-user-permissions';

const Home = lazy(() => import('src/modules/home'));
const Locations = lazy(() => import('src/modules/locations'));
const Products = lazy(() => import('src/modules/products'));
const ProductItineraries = lazy(
  () => import('src/modules/product-itineraries')
);
const SalesCycles = lazy(() => import('src/modules/sales-cycles'));
const Customers = lazy(() => import('src/modules/customers'));
const Bookings = lazy(() => import('src/modules/bookings'));
const ManageBooking = lazy(() => import('src/modules/bookings/manage-booking'));
const ViewingsDashboard = lazy(() => import('src/modules/viewings-dashboard'));
const AzureLoginContainer = lazy(() => import('src/modules/azure-login'));
const NotAuthorized = lazy(() => import('src/modules/not-authorized'));
const RequireAuth = lazy(() => import('src/common/require-auth'));
const Reports = lazy(() => import('src/modules/reports'));
const LotUploads = lazy(() => import('src/modules/lot-uploads'));
const Users = lazy(() => import('src/modules/users'));

export const RoutesContainer = () => {
  const {
    featureFlags: { lotUploads }
  } = useEnv();

  return (
    <Box>
      <Suspense fallback={<LoadingIndicator text="Loading Page..." />}>
        <QueryParamProvider ReactRouterRoute={RouteAdaptor}>
          <Routes>
            <Route path="/" element={<AzureLoginContainer />} />
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="/home" element={<Home />} />
            <Route
              path="/locations"
              element={
                <RequireAuth permission={tokenUserPermissions.LOCATION.GET}>
                  <Locations />
                </RequireAuth>
              }
            />
            <Route
              path="/locations/manage"
              element={
                <RequireAuth permission={tokenUserPermissions.LOCATION.POST}>
                  <CreateLocation />
                </RequireAuth>
              }
            />
            <Route
              path="/locations/manage/:locationId"
              element={
                <RequireAuth permission={tokenUserPermissions.LOCATION.POST}>
                  <CreateLocation />
                </RequireAuth>
              }
            />
            <Route
              path="/products"
              element={
                <RequireAuth permission={tokenUserPermissions.PRODUCT.GET}>
                  <Products />
                </RequireAuth>
              }
            />
            <Route
              path="/product-itineraries"
              element={
                <RequireAuth
                  permission={tokenUserPermissions.PRODUCT_ITINERARY.GET}
                >
                  <ProductItineraries />
                </RequireAuth>
              }
            />
            <Route
              path="/sales-cycles"
              element={
                <RequireAuth permission={tokenUserPermissions.SALES_CYCLE.GET}>
                  <SalesCycles />
                </RequireAuth>
              }
            />

            <Route
              path="/customers"
              element={
                <RequireAuth permission={tokenUserPermissions.CUSTOMER.GET}>
                  <Customers />
                </RequireAuth>
              }
            />

            <Route
              path="/bookings"
              element={
                <RequireAuth permission={tokenUserPermissions.BOOKING.FIND}>
                  <Bookings />
                </RequireAuth>
              }
            />

            <Route
              path="/bookings/create"
              element={
                <RequireAuth permission={tokenUserPermissions.BOOKING.POST}>
                  <ManageBooking />
                </RequireAuth>
              }
            />

            <Route
              path="/bookings/edit/:bookingId"
              element={
                <RequireAuth permission={tokenUserPermissions.BOOKING.PUT}>
                  <ManageBooking />
                </RequireAuth>
              }
            />
            <Route
              path="/viewings-dashboard"
              element={
                <RequireAuth permission={tokenUserPermissions.DASHBOARD.GET}>
                  <ViewingsDashboard />
                </RequireAuth>
              }
            />

            <Route
              path="/reports"
              element={
                <RequireAuth permission={tokenUserPermissions.DASHBOARD.GET}>
                  <Reports />
                </RequireAuth>
              }
            />

            <Route
              path="/users"
              element={
                <RequireAuth permission={tokenUserPermissions.USER.GET}>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/users/manage"
              element={
                <RequireAuth permission={tokenUserPermissions.USER.POST}>
                  <CreateUser />
                </RequireAuth>
              }
            />
            <Route
              path="/users/manage/:userId"
              element={
                <RequireAuth permission={tokenUserPermissions.USER.FIND}>
                  <CreateUser />
                </RequireAuth>
              }
            />

            {lotUploads && (
              <Route
                path="/lot-uploads"
                element={
                  <RequireAuth permission={tokenUserPermissions.PRODUCT.GET}>
                    <LotUploads />
                  </RequireAuth>
                }
              />
            )}

            <Route path="/not-found" element={<NotFoundContainer />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </QueryParamProvider>
      </Suspense>
    </Box>
  );
};
