import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';
import type {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import axios from 'axios';
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAuthenticationContext, useEnv } from '..';

type ServiceProviderType = {
  auctionsApi: AxiosInstance;
  bookingsApi: AxiosInstance;
  authApi: AxiosInstance;
  hubConnection: HubConnection;
};

const ServiceProviderContext = createContext<ServiceProviderType | undefined>(
  undefined
);

const logRequest = (requestConfig: InternalAxiosRequestConfig) => {
  // eslint-disable-next-line no-console
  console.log({ type: 'REQUEST', url: requestConfig.url, requestConfig });
  return requestConfig;
};

const logResponse = (response: AxiosResponse) => {
  // eslint-disable-next-line no-console
  console.log({
    type: 'RESPONSE',
    url: response.config.url,
    data: response.data,
    response
  });
  return response;
};

export const ServiceProvider: FC = ({ children }) => {
  const {
    auctionsApiUrl,
    bookingsApiUrl,
    authenticationApiUrl,
    viewingsHubPath,
    featureFlags: { debugLogging }
  } = useEnv();
  const { isLoggedIn, signIn, getToken, refreshAccessToken, canRefresh } =
    useAuthenticationContext();

  const appInsights = useAppInsightsContext();

  const [hubConnection] = useState<HubConnection>(
    new HubConnectionBuilder()
      .withUrl(`${auctionsApiUrl}/${viewingsHubPath}`)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Warning)
      .build()
  );

  useEffect(() => {
    (async () => {
      await hubConnection.start();

      hubConnection.onreconnecting((error) => {
        if (error) {
          appInsights.trackException({
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: {
              hubConnectionState: hubConnection.state,
              connectionId: hubConnection.connectionId
            }
          });
        } else {
          appInsights.trackEvent({
            name: 'SignalRConnectionReconnecting',
            properties: {
              hubConnectionState: hubConnection.state,
              connectionId: hubConnection.connectionId
            }
          });
        }
      });

      hubConnection.onclose((error) => {
        if (error) {
          appInsights.trackException({
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: {
              hubConnectionState: hubConnection.state,
              connectionId: hubConnection.connectionId
            }
          });
        } else {
          appInsights.trackEvent({
            name: 'SignalRConnectionClosed',
            properties: {
              hubConnectionState: hubConnection.state,
              connectionId: hubConnection.connectionId
            }
          });
        }
      });
    })();

    return () => void hubConnection.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRefreshToken = useCallback(
    async (config: InternalAxiosRequestConfig) => {
      if (!(await isLoggedIn(false))) {
        signIn();
      }

      if (canRefresh()) {
        await refreshAccessToken();
      }
      config.headers.set('Authorization', `Bearer ${getToken()}`);
      return config;
    },
    [getToken, isLoggedIn, refreshAccessToken, signIn, canRefresh]
  );

  const trackErrorWithAppInsights = useCallback(
    (error: AxiosError) => {
      appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...error }
      });
      throw error;
    },
    [appInsights]
  );

  const auctionsApi = useMemo(() => {
    const auctionsInstance = axios.create({
      baseURL: auctionsApiUrl
    });
    auctionsInstance.interceptors.request.use(
      checkRefreshToken,
      trackErrorWithAppInsights
    );

    if (debugLogging) {
      auctionsInstance.interceptors.request.use(logRequest);
    }

    auctionsInstance.interceptors.response.use(
      (response) => response,
      trackErrorWithAppInsights
    );

    if (debugLogging) {
      auctionsInstance.interceptors.response.use(logResponse);
    }

    return auctionsInstance;
  }, [
    auctionsApiUrl,
    checkRefreshToken,
    trackErrorWithAppInsights,
    debugLogging
  ]);

  const bookingsApi = useMemo(() => {
    const bookingsInstance = axios.create({
      baseURL: bookingsApiUrl
    });
    bookingsInstance.interceptors.request.use(
      checkRefreshToken,
      trackErrorWithAppInsights
    );

    if (debugLogging) {
      bookingsInstance.interceptors.request.use(logRequest);
    }

    bookingsInstance.interceptors.response.use(
      (response) => response,
      trackErrorWithAppInsights
    );

    if (debugLogging) {
      bookingsInstance.interceptors.response.use(logResponse);
    }

    return bookingsInstance;
  }, [
    bookingsApiUrl,
    checkRefreshToken,
    trackErrorWithAppInsights,
    debugLogging
  ]);

  const authApi = useMemo(() => {
    const authInstance = axios.create({
      baseURL: authenticationApiUrl
    });
    authInstance.interceptors.request.use(
      checkRefreshToken,
      trackErrorWithAppInsights
    );

    if (debugLogging) {
      authInstance.interceptors.request.use(logRequest);
    }

    authInstance.interceptors.response.use(
      (response) => response,
      trackErrorWithAppInsights
    );

    if (debugLogging) {
      authInstance.interceptors.response.use(logResponse);
    }

    return authInstance;
  }, [
    authenticationApiUrl,
    checkRefreshToken,
    trackErrorWithAppInsights,
    debugLogging
  ]);

  return (
    <ServiceProviderContext.Provider
      value={{ auctionsApi, bookingsApi, authApi, hubConnection }}
    >
      {children}
    </ServiceProviderContext.Provider>
  );
};

export const useServiceProvider = () => {
  const context = useContext(ServiceProviderContext);

  if (!context) {
    throw new Error(
      'Service Provider Context cannot be used outside of Service Provider Context Provider'
    );
  }

  return context;
};
