import { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import {
  PaginatedResponse,
  ProductItinerary,
  ProductItineraryView,
  Request,
  BookingInputFromCurrentItineraryAndSalesCycle
} from 'src/types';
import { LocationAvailability } from 'src/types/models/LocationAvailability.type';

type StringLocationAvailability = LocationAvailability & {
  date: string;
};

export const useProductItineraryService = () => {
  const { auctionsApi } = useServiceProvider();

  const getProductItineraryList = useCallback(
    async ({
      page,
      pageSize,
      search,
      filters
    }: Request): Promise<PaginatedResponse<ProductItineraryView>> => {
      const response: AxiosResponse<PaginatedResponse<ProductItineraryView>> =
        await auctionsApi.get('/ProductItineraryLocations', {
          params: {
            page,
            pageSize,
            search,
            salesCycleId: get(filters, 'salesCycleId', -1)
          }
        });
      return response.data;
    },
    [auctionsApi]
  );

  const uploadProductItineraryList = useCallback(
    async (rows: ProductItinerary[], salesCycleId: number) => {
      try {
        return await auctionsApi.post('/ProductItineraryLocations', {
          productItineraries: rows,
          salesCycleId
        });
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const getLocationAvailableDates = useCallback(
    async (
      locationId: number,
      salesCycleId: number
    ): Promise<StringLocationAvailability[]> => {
      const response: AxiosResponse<StringLocationAvailability[]> =
        await auctionsApi.get(
          '/ProductItineraryLocations/locationAvailableDates',
          {
            params: {
              locationId,
              salesCycleId
            }
          }
        );
      return response.data;
    },
    [auctionsApi]
  );

  const UpdateProductItineraryLocationDates = useCallback(
    async (
      salesCycleId: number,
      location: {
        documentName: string;
        location: string;
        inDate: string;
        outDate: string;
      }
    ): Promise<number | null> => {
      try {
        const response: AxiosResponse<number> = await auctionsApi.put(
          `/ProductItineraryLocations/${salesCycleId}/${location.documentName}/${location.location}`,
          location,
          {
            headers: {
              accept: 'text/plain',
              contentType: 'application/json'
            }
          }
        );
        return response.data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error updating product itinerary location:', error);
        return null;
      }
    },
    [auctionsApi]
  );

  const getBookingInputFromCurrentItineraryAndSalesCycle = useCallback(
    async (
      locationId: string,
      salesCycleId: string
    ): Promise<BookingInputFromCurrentItineraryAndSalesCycle> => {
      const response: AxiosResponse<BookingInputFromCurrentItineraryAndSalesCycle> =
        await auctionsApi.get(
          '/ProductItineraryLocations/BookingInputFromCurrentItineraryAndSalesCycle',
          {
            params: {
              locationId,
              salesCycleId
            }
          }
        );
      return response.data;
    },
    [auctionsApi]
  );

  return {
    getProductItineraryList,
    uploadProductItineraryList,
    getLocationAvailableDates,
    UpdateProductItineraryLocationDates,
    getBookingInputFromCurrentItineraryAndSalesCycle
  };
};
