import { useEffect, useState } from 'react';
import { validatePermissions } from 'src/lib/authentication/validate-permissions';
import { useAuthenticationContext } from '../authentication-context-provider/AuthenticationContextProvider';

export const RequirePermission = ({
  children,
  permission
}: {
  children: JSX.Element;
  permission: string;
}) => {
  const [authorised, setAuthorised] = useState(false);
  const { getToken } = useAuthenticationContext();

  useEffect(() => {
    const validAccessToken = getToken();
    if (!validAccessToken) {
      setAuthorised(false);
      return;
    }

    if (!permission) {
      setAuthorised(true);
      return;
    }
    validatePermissions(setAuthorised, permission, validAccessToken);
  }, [getToken, permission]);

  if (authorised) {
    return children;
  }

  return null;
};
