import { get } from 'lodash';

export const ascendingComparator =
  <T>(key: keyof T) =>
  (first: T, second: T): number => {
    if (get(first, key) < get(second, key)) {
      return -1;
    }

    if (get(first, key) > get(second, key)) {
      return 1;
    }

    return 0;
  };
