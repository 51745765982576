import { Box, Button, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { styles } from 'src/modules/home/home.styles';
import { useLocationService } from 'src/services';
import { Room } from 'src/types';
import { CreateRoom } from './CreateRoom';

interface IRoomsFieldArray {
  locationId?: string;
}

export const RoomsFieldArray = ({ locationId }: IRoomsFieldArray) => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rooms'
  });
  const [bookedRooms, setBookedRooms] = useState<number[]>([]);
  const [activeRooms, setActiveRooms] = useState<number[]>([]);
  const { getBookedRooms, getRoomsWithSessions, getLocation } =
    useLocationService();

  useEffect(() => {
    if (locationId) {
      (async () => {
        const numericLocationId = parseInt(locationId);

        const location = await getLocation(numericLocationId);
        const bookedRoomsResponse = await getBookedRooms(numericLocationId);
        setBookedRooms(bookedRoomsResponse);

        const activeRoomsResponse = (
          await getRoomsWithSessions(location.locationId ?? -1)
        ).map(({ roomId }) => roomId);
        setActiveRooms(activeRoomsResponse);
      })();
    }
  }, [getBookedRooms, getLocation, getRoomsWithSessions, locationId]);

  const getWarningProps = (
    roomIndex: number
  ): { allowInteraction?: 'warn' | 'disallow'; feedbackMessage?: string } => {
    const room: Room | undefined = getValues(`rooms.${roomIndex}`);
    const roomId = room ? room.roomId : undefined;

    if (!roomId) {
      return {};
    }

    if (activeRooms.includes(roomId)) {
      return {
        allowInteraction: 'disallow',
        feedbackMessage: 'This room is being used for a viewing session'
      };
    } else if (bookedRooms.includes(roomId)) {
      return {
        allowInteraction: 'warn',
        feedbackMessage:
          'There are bookings currently associated with this Room, please review and update them accordingly'
      };
    }

    return {};
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={4}
        alignItems={'center'}
      >
        <Typography variant="h6" sx={styles.titleRoom}>
          Rooms
        </Typography>
      </Box>
      {fields.map((room, index) => (
        <CreateRoom
          key={room.id}
          index={index}
          control={control}
          onDelete={remove}
          {...getWarningProps(index)}
        />
      ))}
      <Box display="flex" alignItems={'left'} mt={1}>
        <Button
          disableRipple
          size="large"
          style={{ justifyContent: 'left' }}
          onClick={() => append({ name: '', capacity: 1, isAvailable: true })}
          startIcon={<AddIcon />}
        >
          Add Room
        </Button>
      </Box>
    </>
  );
};
