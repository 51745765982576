import { AxiosError, AxiosResponse } from 'axios';
import { Request, PaginatedResponse, NamedCustomer } from 'src/types';
import { Customer } from 'src/types/models/Customer.type';
import { CreateCustomer } from 'src/types/models/CreateCustomer.type';
import { UpdateCustomer } from 'src/types/models/UpdateCustomer.type';
import { GetCustomer } from 'src/types/models/GetCustomer.type';
import { get } from 'lodash';
import { CustomerUpload } from 'src/types/models/CustomerUpload.type';
import { useServiceProvider } from 'src/common';
import { useCallback } from 'react';

export const useCustomerService = () => {
  const { auctionsApi } = useServiceProvider();

  const getCustomersList = useCallback(
    async ({
      page,
      pageSize,
      search,
      filters
    }: Request): Promise<PaginatedResponse<Customer>> => {
      const response: AxiosResponse<PaginatedResponse<Customer>> =
        await auctionsApi.get('/Customers', {
          params: {
            page,
            pageSize,
            search,
            locationName: get(filters, 'LocationName', ''),
            ownerName: get(filters, 'OwnerName', '')
          }
        });
      return response.data;
    },
    [auctionsApi]
  );

  const getCustomerDistinctOwnersList = useCallback(async (): Promise<
    string[]
  > => {
    const response: AxiosResponse<string[]> = await auctionsApi.get(
      '/Customers/accountOwners'
    );
    return response.data;
  }, [auctionsApi]);

  const getCustomerDistinctOfficesList = useCallback(async (): Promise<
    string[]
  > => {
    const response: AxiosResponse<string[]> = await auctionsApi.get(
      '/Customers/accountOffices'
    );
    return response.data;
  }, [auctionsApi]);

  const uploadCustomersList = useCallback(
    async (rows: CustomerUpload[]) => {
      try {
        return await auctionsApi.post('/Customers', {
          customers: rows
        });
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const createSingleCustomer = useCallback(
    async (customer: CreateCustomer) => {
      interface CustomerResponse {
        customerId: string;
      }

      const response: AxiosResponse<CustomerResponse> =
        await auctionsApi.post<CustomerResponse>(
          '/Customers/createSingle',
          customer
        );
      return response.data;
    },
    [auctionsApi]
  );

  const getCustomersByIds = useCallback(
    async (customerIds?: string[]) => {
      const response: AxiosResponse<NamedCustomer[]> = await auctionsApi.post(
        '/Customers/GetCustomersByIds',
        { customerIds }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const getCustomerById = useCallback(
    async (accountId: string): Promise<CreateCustomer> => {
      const response: AxiosResponse<GetCustomer> = await auctionsApi.get(
        `/Customers/${accountId}`
      );
      const data = response.data;

      const mappedData: CreateCustomer = {
        ...data,
        accountName: data.name
      };

      return mappedData;
    },
    [auctionsApi]
  );

  const updateCustomer = useCallback(
    async (customer: UpdateCustomer) => {
      const response: AxiosResponse<UpdateCustomer> = await auctionsApi.put(
        '/Customers',
        customer
      );
      return response.data;
    },
    [auctionsApi]
  );

  return {
    getCustomersList,
    getCustomerDistinctOwnersList,
    getCustomerDistinctOfficesList,
    uploadCustomersList,
    createSingleCustomer,
    getCustomersByIds,
    getCustomerById,
    updateCustomer
  };
};
