import { Box, Typography } from '@mui/material';

export const NotFound = () => {
  return (
    <Box>
      <Typography variant="subtitle2" component="h1" fontWeight={500}>
        404 - Not Found
      </Typography>
      <Typography component="h6">
        We were not able to find the page you were looking for.
      </Typography>
    </Box>
  );
};
