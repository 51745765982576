import {
  Box,
  createTheme,
  CSSObject,
  Drawer,
  List,
  Theme,
  ThemeProvider,
  Toolbar as NavigationOffset,
  useTheme
} from '@mui/material';
import { NavigationLink } from './NavigationLink';
import HomeIcon from '@mui/icons-material/Home';
import DiamondIcon from '@mui/icons-material/Diamond';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GroupIcon from '@mui/icons-material/Group';
import BookIcon from '@mui/icons-material/Book';
import { stylesOverride } from 'src/styles-override';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ImageIcon from '@mui/icons-material/Image';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import RequirePermission from 'src/common/require-permission';
import { tokenUserPermissions } from 'src/lib/constants/token-user-permissions';
import { useEnv } from '..';

interface NavigationDrawerProps {
  drawerWidth: number;
  isOpen: boolean;
}

export const NavigationDrawer = ({
  drawerWidth,
  isOpen
}: NavigationDrawerProps) => {
  const theme = useTheme();

  const {
    featureFlags: { lotUploads }
  } = useEnv();

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden',
    backgroundColor: 'primary.main',
    color: 'white'
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    backgroundColor: 'primary.main',
    color: 'white',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`
    }
  });

  const openedStyles = {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  };

  const closedStyles = {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  };

  const styles = {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(isOpen ? openedStyles : closedStyles)
    }
  } as const;

  const navigationItems = [
    {
      to: '/',
      primary: 'Home',
      icon: <HomeIcon />,
      permission: ''
    },
    {
      to: '/locations',
      primary: 'Locations',
      icon: <LocationOnIcon />,
      permission: tokenUserPermissions.LOCATION.GET
    },
    {
      to: '/sales-cycles',
      primary: 'Sales Cycles',
      icon: <RestartAltIcon />,
      permission: tokenUserPermissions.SALES_CYCLE.GET
    },
    {
      to: '/products',
      primary: 'Products',
      icon: <DiamondIcon />,
      permission: tokenUserPermissions.PRODUCT.GET
    },
    {
      to: '/product-itineraries',
      primary: 'Product Itineraries',
      icon: <LocalShippingIcon />,
      permission: tokenUserPermissions.PRODUCT_ITINERARY.GET
    },
    {
      to: '/customers',
      primary: 'Customers',
      icon: <GroupIcon />,
      permission: tokenUserPermissions.CUSTOMER.GET
    },
    {
      to: '/users',
      primary: 'Users',
      icon: <AdminPanelSettingsIcon />,
      permission: tokenUserPermissions.USER.GET
    },
    {
      to: '/bookings',
      primary: 'Bookings',
      icon: <BookIcon />,
      permission: tokenUserPermissions.BOOKING.FIND
    },
    {
      to: '/viewings-dashboard',
      primary: 'Viewings Dashboard',
      icon: <GridViewIcon />,
      permission: tokenUserPermissions.DASHBOARD.GET
    },
    {
      to: '/reports',
      primary: 'Reporting',
      icon: <AssessmentIcon />,
      permission: ''
    }
  ];

  if (lotUploads) {
    navigationItems.push({
      to: '/lot-uploads',
      primary: 'LotUploads',
      icon: <ImageIcon />,
      permission: tokenUserPermissions.PRODUCT.GET
    });
  }

  return (
    <Drawer
      variant="permanent"
      sx={styles.drawer}
      PaperProps={{ sx: { borderRight: 0 } }}
    >
      <NavigationOffset />
      <ThemeProvider theme={createTheme(stylesOverride)}>
        <Box
          sx={{
            overflow: 'auto',
            width: drawerWidth,
            boxSizing: 'border-box'
          }}
        >
          <List>
            {navigationItems.map((item, index) => (
              <RequirePermission key={index} permission={item.permission}>
                <NavigationLink
                  to={item.to}
                  primary={item.primary}
                  icon={item.icon}
                />
              </RequirePermission>
            ))}
          </List>
        </Box>
      </ThemeProvider>
    </Drawer>
  );
};
