import { usePageTitle } from '..';
import { useDrawerOpen } from '../use-drawer-open/useDrawerOpen';
import { NavigationBar } from './NavigationBar';
import { NavigationDrawer } from './NavigationDrawer';
import useSound from 'use-sound';
import NotificationSound from '../../sounds/NotificationSound.mp3';
import { useSignalRSoundNotification } from 'src/hooks/use-signal-r/useSignalRSoundNotification';
import { useMemo } from 'react';

interface NavigationContainerProps {
  drawerWidth?: number;
}

export const NavigationContainer = ({
  drawerWidth = 240
}: NavigationContainerProps) => {
  const [isOpen, toggleIsOpen] = useDrawerOpen();
  const { pageTitle } = usePageTitle();

  const { notificationSound } = useSignalRSoundNotification();
  const [play] = useSound(NotificationSound as string);

  useMemo(() => {
    if (notificationSound !== null) {
      play();
    }
  }, [notificationSound, play]);

  return (
    <>
      <NavigationBar
        isOpen={isOpen}
        drawerWidth={drawerWidth}
        toggleIsOpen={toggleIsOpen}
        pageTitle={pageTitle}
      />
      <NavigationDrawer drawerWidth={drawerWidth} isOpen={isOpen} />
    </>
  );
};
