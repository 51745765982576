import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { toUTC } from 'src/lib/date-format/date-conversion';
import { Booking, ProductItineraryLocationId, BookingExport } from 'src/types';
import { BookingCreate } from 'src/types/models/BookingCreate.type';
import { CancellationType } from 'src/types/models/CancellationType.type';

export const useBookingsService = () => {
  const { bookingsApi } = useServiceProvider();

  const getListOfBookings = useCallback(
    async (locationId: number, bookingDate: Date): Promise<Booking[]> => {
      const response: AxiosResponse<Booking[]> = await bookingsApi.get(
        '/AuctionBookings',
        {
          params: {
            locationId,
            bookingDate: toUTC(bookingDate)
          }
        }
      );
      return response.data.map((data) => ({
        ...data,
        bookingStart: new Date(Date.parse(String(data.bookingStart))),
        bookingEnd: new Date(Date.parse(String(data.bookingEnd)))
      }));
    },
    [bookingsApi]
  );

  const createOrEditBooking = useCallback(
    async (booking: BookingCreate): Promise<number> => {
      const response: AxiosResponse<number> = await bookingsApi.post(
        '/AuctionBookings',
        {
          ...booking,
          startDate: toUTC(booking.startDate),
          endDate: toUTC(booking.endDate),
          startDateUtc: booking.startDate,
          endDateUtc: booking.endDate
        },
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [bookingsApi]
  );

  const deleteBooking = useCallback(
    async (auctionBookingId: string) => {
      const response: AxiosResponse<number> = await bookingsApi.delete(
        `/AuctionBookings/${auctionBookingId}`,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response;
    },
    [bookingsApi]
  );

  const cancelAndDeleteBooking = useCallback(
    async (
      auctionBookingId: string,
      cancellationTypeId: number,
      cancellationComment: string | undefined
    ) => {
      const response: AxiosResponse<number> = await bookingsApi.delete(
        '/AuctionBookings/CancelAndDelete',
        {
          params: {
            auctionBookingId,
            cancellationTypeId,
            cancellationComment
          },
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response;
    },
    [bookingsApi]
  );

  const getBookingsExport = useCallback(
    async (salesCycleId: number, salesCycleName: string) => {
      const response: AxiosResponse<Blob> = await bookingsApi.post(
        '/AuctionBookings/BookingsExport',
        {
          salesCycleId,
          salesCycleName
        },
        {
          responseType: 'blob'
        }
      );

      return response.data;
    },
    [bookingsApi]
  );

  const getBookingsExportExcel = useCallback(
    async (salesCycleId: number, salesCycleName: string) => {
      const response: AxiosResponse<BookingExport[]> = await bookingsApi.post(
        '/AuctionBookings/BookingsExportExcel',
        {
          salesCycleId,
          salesCycleName
        }
      );

      return response.data;
    },
    [bookingsApi]
  );

  const getCustomerIds = useCallback(
    async (salesCycleId: number) => {
      const response: AxiosResponse<string[]> = await bookingsApi.get(
        '/AuctionBookings/Customers',
        {
          params: { salesCycleId }
        }
      );
      return response.data;
    },
    [bookingsApi]
  );

  const getListOfBookingsOutsideDateRange = useCallback(
    async (
      productItineraryLocations: ProductItineraryLocationId[],
      salesCycleId: number | null
    ): Promise<Booking[]> => {
      const response: AxiosResponse<Booking[]> = await bookingsApi.post(
        '/AuctionBookings/BookingsOutsideDateRange',
        {
          productItineraryLocations,
          salesCycleId
        }
      );
      return response.data.map((data) => ({
        ...data,
        bookingStart: new Date(Date.parse(String(data.bookingStart))),
        bookingEnd: new Date(Date.parse(String(data.bookingEnd)))
      }));
    },
    [bookingsApi]
  );

  const getCancellationTypes = useCallback(async () => {
    const response: AxiosResponse<CancellationType[]> = await bookingsApi.get(
      '/AuctionBookings/all'
    );
    return response.data;
  }, [bookingsApi]);

  return {
    getListOfBookings,
    createOrEditBooking,
    deleteBooking,
    cancelAndDeleteBooking,
    getBookingsExport,
    getBookingsExportExcel,
    getCustomerIds,
    getListOfBookingsOutsideDateRange,
    getCancellationTypes
  };
};
