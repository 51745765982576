import { Box, styled, Typography, Chip } from '@mui/material';
import { parse } from 'papaparse';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ProductUpload, CustomerUpload } from 'src/types';
import { get, head } from 'lodash';
import FolderIcon from '@mui/icons-material/Folder';
import { ProductItineraryUpload } from 'src/types/models/ProductItineraryUpload.type';

interface IFileUploadZone<
  T extends ProductUpload | CustomerUpload | ProductItineraryUpload
> {
  setUploadRows: (rows: T[]) => void;
  hasUploadError: boolean;
  headerLookup: Record<string, string>;
  transform?: (value: string, header: string) => string | number | boolean;
}

const Input = styled('input')({
  display: 'none'
});

export const FileUploadZone = <
  T extends ProductUpload | CustomerUpload | ProductItineraryUpload
>({
  setUploadRows,
  hasUploadError,
  headerLookup,
  transform
}: IFileUploadZone<T>) => {
  const [fileName, setFileName] = useState('');

  const transformHeader = (header: string): string => {
    const lowercaseHeaderLookup = Object.fromEntries(
      Object.entries(headerLookup).map(([key, value]) => [
        key.toLowerCase(),
        value
      ])
    );

    return get(lowercaseHeaderLookup, header.toLowerCase(), header);
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = head(acceptedFiles);
    if (file) {
      setFileName(file.name);

      const fileReader = new FileReader();
      fileReader.readAsText(file);

      fileReader.onload = (event) => {
        const fileResults = event?.target?.result as string;
        parse<T>(fileResults, {
          header: true,
          delimiter: ',',
          skipEmptyLines: true,
          transformHeader,
          transform,
          complete: (results) => {
            setUploadRows(results.data);
          }
        });
      };
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: 'text/csv',
    onDrop
  });

  const handleDeleteFile = () => {
    setFileName('');
    setUploadRows([]);
  };

  return (
    <>
      {fileName === '' ? (
        <Box
          display="flex"
          alignItems="center"
          alignContent="center"
          justifyContent="space-around"
          flexDirection="column"
          border="2px dashed"
          borderColor="main"
          p={5}
          {...getRootProps({ className: 'dropzone' })}
          sx={{ cursor: 'pointer' }}
        >
          <Input {...getInputProps()} />
          <Typography color="grey.500">
            Drag and drop your file here, or click to select a file
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="subtitle1">
            File Name:{' '}
            <Chip
              label={fileName}
              variant="outlined"
              color={hasUploadError ? 'error' : 'default'}
              icon={<FolderIcon />}
              onDelete={() => handleDeleteFile()}
            />
          </Typography>
        </Box>
      )}
    </>
  );
};
