import { Box, Typography } from '@mui/material';

interface IUnavailableMessage {
  message: string;
}

export const UnavailableMessage = ({ message }: IUnavailableMessage) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
};
