import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { PaginatedResponse, Request, Response, Role, User } from 'src/types';

export const useUserService = () => {
  const { authApi } = useServiceProvider();

  const getUsersList = useCallback(
    async ({
      page,
      pageSize,
      search
    }: Request): Promise<PaginatedResponse<User>> => {
      const response: AxiosResponse<PaginatedResponse<User>> =
        await authApi.get('/api/Users/get-all-users', {
          params: {
            pageSize,
            page,
            search
          }
        });
      return response.data;
    },
    [authApi]
  );

  const getUser = useCallback(
    async (userId: number) => {
      const response: AxiosResponse<User> = await authApi.get(
        `api/Users/${userId}`
      );
      return response.data;
    },
    [authApi]
  );

  const getRolesList = useCallback(async (): Promise<Role[]> => {
    const response: AxiosResponse<Role[]> = await authApi.get(
      '/api/Roles/get-all-roles'
    );
    return response.data;
  }, [authApi]);

  const createOrEditUser = useCallback(
    async (user: User): Promise<Response<User>> => {
      let routeSuffix = '/api/Users/create-or-edit-user?';

      if (user.userId) {
        routeSuffix += `userId=${user.userId}`;
      }
      const response: AxiosResponse<Response<User>> = await authApi.post(
        routeSuffix,
        user,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [authApi]
  );

  return {
    getUsersList,
    getUser,
    getRolesList,
    createOrEditUser
  };
};
