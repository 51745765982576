import { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { Request, PaginatedResponse, Product } from 'src/types';
import { ProductNameAndLotNumber } from 'src/types/models/ProductNameAndLotNumber.type';
import { ProductUpload } from 'src/types/models/ProductUpload.type';

export const useProductService = () => {
  const { auctionsApi } = useServiceProvider();

  const getProductsList = useCallback(
    async ({
      page,
      pageSize,
      search,
      filters
    }: Request): Promise<PaginatedResponse<Product>> => {
      const response: AxiosResponse<PaginatedResponse<Product>> =
        await auctionsApi.get('/Products', {
          params: {
            page,
            pageSize,
            search,
            salesCycleId: get(filters, 'salesCycleId', -1)
          }
        });
      return response.data;
    },
    [auctionsApi]
  );

  const uploadProductsList = useCallback(
    async (rows: ProductUpload[], salesCycleId: number) => {
      try {
        return await auctionsApi.post('/Products', {
          products: rows,
          salesCycleId
        });
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const enableDisableProduct = useCallback(
    async (productId: number, enabled: boolean) => {
      try {
        return await auctionsApi.post('/Products/EnableDisable', {
          productId,
          enabled
        });
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const getProductNames = useCallback(async () => {
    const response: AxiosResponse<ProductNameAndLotNumber[]> =
      await auctionsApi.get('/Products/ProductNames');

    return response.data;
  }, [auctionsApi]);

  return {
    getProductsList,
    uploadProductsList,
    enableDisableProduct,
    getProductNames
  };
};
