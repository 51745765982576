import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { Session } from 'src/types';

export const useSessionsService = () => {
  const { auctionsApi } = useServiceProvider();

  const createSession = useCallback(
    async (session: Omit<Session, 'sessionStatusId'>): Promise<Session> => {
      const response: AxiosResponse<Session> = await auctionsApi.post(
        '/Sessions',
        session,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const getSessionByAuctionBookingId = useCallback(
    async (auctionBookingId: string) => {
      const response: AxiosResponse<Session> = await auctionsApi.get(
        `/Sessions/AuctionBookingId?auctionBookingId=${auctionBookingId}`
      );
      return response.data;
    },
    [auctionsApi]
  );

  const updateSessionMaxLots = useCallback(
    async (sessionId?: string, maxLots?: number) => {
      const response: AxiosResponse<number> = await auctionsApi.put(
        '/Sessions/MaxLots',
        { sessionId, maxLots },
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response;
    },
    [auctionsApi]
  );

  return { getSessionByAuctionBookingId, createSession, updateSessionMaxLots };
};
