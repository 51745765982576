import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import {
  PaginatedResponse,
  Location,
  Request,
  ActiveSession,
  Room
} from 'src/types';
import { LocationRow } from 'src/types/models/LocationRow.type';

export const useLocationService = () => {
  const { auctionsApi, bookingsApi } = useServiceProvider();

  const getLocationsList = useCallback(
    async (queryParams: Request): Promise<PaginatedResponse<LocationRow>> => {
      const response: AxiosResponse<PaginatedResponse<LocationRow>> =
        await bookingsApi.get('/Locations', {
          params: queryParams
        });
      return response.data;
    },
    [bookingsApi]
  );

  const createOrEditLocation = useCallback(
    async (location: Location): Promise<number> => {
      const response: AxiosResponse<number> = await bookingsApi.post(
        '/Locations',
        location,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [bookingsApi]
  );

  const getLocation = useCallback(
    async (locationId: number) => {
      const response: AxiosResponse<Location> = await bookingsApi.get(
        `/Locations/${locationId}`
      );
      return response.data;
    },
    [bookingsApi]
  );

  const getListOfRoomsByLocationId = useCallback(
    async (locationId: number): Promise<Room[]> => {
      const response: AxiosResponse<Location> = await bookingsApi.get(
        `/Locations/${locationId}`
      );

      return response.data.rooms ?? [];
    },
    [bookingsApi]
  );

  const getLocationList = useCallback(async () => {
    const response: AxiosResponse<Location[]> = await bookingsApi.get(
      '/Locations/all'
    );
    return response.data;
  }, [bookingsApi]);

  const getBookedRooms = useCallback(
    async (locationId: number) => {
      const response: AxiosResponse<number[]> = await bookingsApi.get(
        `/AuctionBookings/BookedRooms?locationId=${locationId}`
      );
      return response.data;
    },
    [bookingsApi]
  );

  const getRoomsWithSessions = useCallback(
    async (locationId: number) => {
      const response: AxiosResponse<ActiveSession[]> = await auctionsApi.get(
        '/Sessions/GetActiveSessions',
        {
          params: {
            locationId
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  return {
    getLocationsList,
    createOrEditLocation,
    getLocation,
    getLocationList,
    getBookedRooms,
    getRoomsWithSessions,
    getListOfRoomsByLocationId
  };
};
