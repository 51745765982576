import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme
} from '@mui/material';
import { CSSProperties, PropsWithChildren, ReactElement } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

interface NavigationLinkProps {
  icon?: ReactElement;
  to: string;
  primary: string;
}

export const NavigationLink = ({
  icon,
  to,
  primary
}: PropsWithChildren<NavigationLinkProps>) => {
  const getStyles = (isActive: boolean): CSSProperties => {
    const styles: SxProps<Theme> = {
      textDecoration: 'none',
      color: 'white',
      '&:hover': {
        backgroundColor: (theme) => theme.palette.primary.light
      }
    };

    const activeStyles: SxProps<Theme> = {
      color: '#12233A',
      backgroundColor: '#f5f5f5',
      '&:hover': {
        backgroundColor: (theme) => theme.palette.grey[200]
      }
    };

    return isActive ? { ...styles, ...activeStyles } : styles;
  };

  return (
    <RouterLink to={to} style={{ textDecoration: 'none' }}>
      {({ isActive }) => (
        <ListItemButton sx={getStyles(isActive)}>
          {icon ? (
            <ListItemIcon
              sx={{
                justifyContent: 'center',
                paddingRight: 2,
                color: isActive ? '#12233A' : 'white'
              }}
            >
              {icon}
            </ListItemIcon>
          ) : null}
          <ListItemText primary={primary} />
        </ListItemButton>
      )}
    </RouterLink>
  );
};
