import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useEnv } from 'src/common';
import { Authentication } from 'src/types/models/Authentication.type';
import { v4 as uuid } from 'uuid';

export const useAuthenticationService = () => {
  const { authenticationApiUrl } = useEnv();
  const appInsights = useAppInsightsContext();

  const trackErrorWithAppInsights = useCallback(
    (error: AxiosError) => {
      appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...error }
      });
      return error;
    },
    [appInsights]
  );

  const authenticationApi = useMemo(() => {
    const authInstance = axios.create({
      baseURL: authenticationApiUrl
    });
    authInstance.interceptors.request.use(
      (response) => response,
      trackErrorWithAppInsights
    );

    authInstance.interceptors.response.use(
      (response) => response,
      trackErrorWithAppInsights
    );
    return authInstance;
  }, [authenticationApiUrl, trackErrorWithAppInsights]);

  const authenticate = useCallback(
    async (
      token: string,
      strategy: string,
      key?: string
    ): Promise<Authentication> => {
      const response: AxiosResponse<Authentication> =
        await authenticationApi.post(
          '/api/Authentication/authenticate',
          {
            strategy: strategy,
            token: token,
            key: key ?? uuid()
          },
          {
            headers: {
              accept: 'text/plain',
              contentType: 'application/json'
            }
          }
        );
      return response.data;
    },
    [authenticationApi]
  );

  return {
    authenticate
  };
};
