import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormState
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton } from 'src/common';
import { useLocationService } from 'src/services';
import { Location } from 'src/types';
import { RoomsFieldArray } from './RoomsFieldArray';

export const CreateLocation = () => {
  const { locationId } = useParams();
  const { getLocation, createOrEditLocation } = useLocationService();

  const methods = useForm<Location>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
      isAvailable: true,
      rooms: []
    }
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    watch,
    clearErrors,
    getValues
  } = methods;

  useEffect(() => {
    const fetchLocation = async () => {
      if (locationId) {
        const location: Location = await getLocation(Number(locationId));
        reset(location);
      }
    };
    void fetchLocation();
  }, [locationId, reset, getLocation]);

  const { isDirty, isValid } = useFormState({ control });
  const { enqueueSnackbar } = useSnackbar();

  const [currentName, setCurrentName] = useState('');
  const [currentCode, setCurrentCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const watchCode = watch('code');
  const watchName = watch('name');

  const userInput = 'userInput';

  const onSubmit: SubmitHandler<Location> = async (data) => {
    try {
      setIsLoading(true);
      await createOrEditLocation(data);

      enqueueSnackbar(`${!locationId ? 'Added' : 'Updated'} location`, {
        variant: 'success'
      });
      navigate('/locations', { replace: true });
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as AxiosError | any;
      const { response } = err;
      const errorsList = response?.data.errors ?? {};

      if (err.message === 'Network Error') {
        enqueueSnackbar('Network Error', {
          variant: 'error'
        });
      }

      if ('Name' in errorsList) {
        setCurrentName(getValues('name'));
        setError('name', {
          type: userInput,
          message: errorsList['Name'][0]
        });
      }

      if ('Code' in errorsList) {
        setCurrentCode(getValues('code'));
        setError('code', {
          type: userInput,
          message: errorsList['Code'][0]
        });
      }

      if (!('Name' in errorsList) && !('Code' in errorsList)) {
        const message: string = response?.data.title;
        enqueueSnackbar(message || 'error', {
          variant: 'error'
        });
      }
      reset(
        {},
        {
          keepErrors: true,
          keepValues: true,
          keepIsValid: true,
          keepDirty: true
        }
      );
      setIsLoading(false);
    }
  };
  const onBlur = () => {
    if (watchName === currentName && watchName !== '') {
      setError('name', {
        type: userInput,
        message: 'A location with that name already exists.'
      });
    } else {
      clearErrors('name');
    }

    if (watchCode === currentCode && watchCode !== '') {
      setError('code', {
        type: userInput,
        message: 'A location with that code already exists.'
      });
    } else {
      clearErrors('code');
    }
  };
  return (
    <Paper sx={{ padding: 2 }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onBlur={onBlur}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box>
            <Box display="flex" justifyContent="space-between">
              <BackButton to="/locations" />
              <LoadingButton
                disabled={!isDirty || !isValid}
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {!locationId ? 'Save Location' : 'Update Location'}
              </LoadingButton>
            </Box>
            <Box mt={3}>
              {!locationId ? (
                <Typography variant="h5">Add Location</Typography>
              ) : (
                <Typography variant="h5">Edit Location</Typography>
              )}
            </Box>

            <Grid container spacing={2} mt={1} alignItems="center">
              <Grid item xs={3}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={
                        errors.name?.type === 'unique' ||
                        errors.name?.type === 'required' ||
                        errors.name?.type === userInput
                      }
                      helperText={
                        errors.name?.type === 'required'
                          ? 'Location name is required.'
                          : '' || errors.name?.type === userInput
                          ? errors.name?.message
                          : ''
                      }
                      id="name"
                      label="Location Name"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="code"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={
                        errors.code?.type === 'unique' ||
                        errors.code?.type === 'required' ||
                        errors.code?.type === userInput
                      }
                      helperText={
                        errors.code?.type === 'required'
                          ? 'Location code is required.'
                          : '' || errors.code?.type === userInput
                          ? errors.code?.message
                          : ''
                      }
                      id="code"
                      label="Location Code"
                    />
                  )}
                />
              </Grid>
              <Grid item xs>
                <Controller
                  name="isAvailable"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box display="flex">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={value}
                            onChange={(event) => onChange(event.target.checked)}
                            color="secondary"
                          />
                        }
                        label="Available"
                      />
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
            <RoomsFieldArray locationId={locationId} />
          </Box>
        </Box>
      </FormProvider>
    </Paper>
  );
};
