import { ErrorMessage } from '@hookform/error-message';
import DeleteIcon from '@mui/icons-material/Delete';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { Room } from 'src/types';
import { TooltipWrapper } from './components/TooltipWrapper';

interface ICreateRoom {
  index: number;
  control: Control;
  onDelete: (index: number) => void;
  allowInteraction?: 'full' | 'warn' | 'disallow';
  feedbackMessage?: string;
}

export const CreateRoom = ({
  index,
  control,
  onDelete,
  allowInteraction = 'full',
  feedbackMessage
}: ICreateRoom) => {
  const confirm = useConfirm();
  const { getValues, setValue } = useFormContext();

  const getRoomNames = (): string[] => {
    const rooms = getValues('rooms') as Room[];
    const roomNames = rooms.map(({ name }: Room) => name);
    roomNames.splice(index, 1);
    return roomNames;
  };

  const renderError = ({ message }: { message: string }) => {
    return (
      <Typography variant="caption" sx={{ color: 'error.main' }}>
        {message}
      </Typography>
    );
  };

  const warnOnDisable = (index: number) => {
    const onChange = () => setValue(`rooms.${index}.isAvailable`, false);
    warnOnChange('Are you sure you want to disable this room?')(onChange);
  };

  const warnOnDelete = (onChange: () => void) => {
    warnOnChange('Are you sure you want to delete this room?')(onChange);
  };

  const warnOnChange = (title: string) => (onChange: () => void) => {
    if (allowInteraction === 'warn') {
      confirm({
        title,
        description: feedbackMessage
      })
        .then(() => onChange())
        .catch(() => {
          return;
        });
    } else {
      onChange();
    }
  };

  return (
    <Box mt={index > 0 ? 2 : 1}>
      <Grid container columnSpacing={2} alignItems={'center'}>
        <Grid item xs={3}>
          <Controller
            name={`rooms.${index}.name`}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'A room name is required'
              },
              validate: (roomName: string) =>
                !getRoomNames().includes(roomName) || 'Room name must be unique'
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  id={`roomName-${index}`}
                  label="Room Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MeetingRoomIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <ErrorMessage
                  name={`rooms.${index}.name`}
                  render={renderError}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={`rooms.${index}.capacity`}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'A room capacity is required'
              },
              min: {
                value: 1,
                message: 'Capacity must be a positive number'
              }
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  id={`capacity-${index}`}
                  label="Capacity"
                />
                <ErrorMessage
                  name={`rooms.${index}.capacity`}
                  render={renderError}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs>
          <Box display="flex" flexDirection="row" alignItems="center">
            <TooltipWrapper
              displayTooltip={allowInteraction === 'disallow'}
              feedbackMessage={feedbackMessage}
            >
              <Controller
                name={`rooms.${index}.isAvailable`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={value as boolean}
                        onChange={(event) =>
                          value ? warnOnDisable(index) : onChange(event)
                        }
                        color="secondary"
                        disabled={allowInteraction === 'disallow'}
                      />
                    }
                    label="Available"
                  />
                )}
              />
            </TooltipWrapper>
            <TooltipWrapper
              displayTooltip={allowInteraction === 'disallow'}
              feedbackMessage={feedbackMessage}
            >
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() => warnOnDelete(() => onDelete(index))}
                disabled={allowInteraction === 'disallow'}
              >
                <DeleteIcon color="error" fontSize="inherit" />
              </IconButton>
            </TooltipWrapper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
