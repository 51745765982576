import { AxiosError, AxiosResponse } from 'axios';
import { useServiceProvider } from 'src/common';
import {
  LocationOverviewResponse,
  ViewingDashboardDeclineRequest,
  ViewingDashboardRequest,
  ViewingDashboardSubmitRequest,
  Location,
  ProductViewingsRequest,
  CurrentProductViewing
} from 'src/types';
import { useCallback } from 'react';
import { toUTC } from 'src/lib/date-format/date-conversion';

export const useViewingDashboardService = () => {
  const { auctionsApi } = useServiceProvider();

  const getListOfViewings = useCallback(
    async (location: Location): Promise<ViewingDashboardRequest[]> => {
      const response: AxiosResponse<ViewingDashboardRequest[]> =
        await auctionsApi.post('/ViewingDashboard/get', {
          location
        });
      return response.data;
    },
    [auctionsApi]
  );

  const updateSessionStatus = useCallback(
    async (
      sessionId: string,
      locationName: string,
      kamName: string
    ): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.put(
        '/ViewingDashboard/Accept',
        {},
        {
          params: {
            sessionId,
            locationName,
            kamName
          },
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const submitViewingDashboardRequest = useCallback(
    async (
      viewingDashboardProductRequest: ViewingDashboardSubmitRequest
    ): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.post(
        '/ViewingDashboard/Complete',
        viewingDashboardProductRequest,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const declineViewingDashboardRequest = useCallback(
    async (
      viewingDashboardDeclineRequest: ViewingDashboardDeclineRequest
    ): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.post(
        '/ViewingDashboard/Decline',
        viewingDashboardDeclineRequest,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const getListOfRoomOverviews = useCallback(
    async (location: Location): Promise<LocationOverviewResponse> => {
      const response: AxiosResponse<LocationOverviewResponse> =
        await auctionsApi.post('/ViewingDashboard/LocationOverview', {
          location
        });

      const rooms = response.data.rooms.map((room) => ({
        ...room,
        startTime: toUTC(new Date(room.startTime)),
        endTime: toUTC(new Date(room.endTime))
      }));

      return { rooms };
    },
    [auctionsApi]
  );

  const updateProductViewingForEnd = useCallback(
    async (productViewingsRequest: ProductViewingsRequest) => {
      try {
        return await auctionsApi.put(
          '/ProductViewings/End',
          productViewingsRequest,
          {
            headers: {
              accept: 'text/plain',
              contentType: 'application/json'
            }
          }
        );
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const getCurrentProductRequesting = useCallback(
    async (sessionId: string) => {
      const response: AxiosResponse<CurrentProductViewing[]> =
        await auctionsApi.get('/ProductViewings/RequestingProducts', {
          params: { sessionId }
        });

      return response.data.map((product) => ({
        ...product,
        startTime: toUTC(new Date(product.startTime)),
        finishTime: toUTC(new Date(product.finishTime))
      }));
    },
    [auctionsApi]
  );

  return {
    getListOfViewings,
    updateSessionStatus,
    submitViewingDashboardRequest,
    declineViewingDashboardRequest,
    getListOfRoomOverviews,
    updateProductViewingForEnd,
    getCurrentProductRequesting
  };
};
