import './fonts/FuturaStdMedium.ttf';
import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Toolbar as NavigationOffset
} from '@mui/material';
import React from 'react';
import { BrowserRouter as Router, Outlet } from 'react-router-dom';
import {
  AppInsightsErrorBoundary,
  ApplicationInsightsContext,
  ErrorBoundary,
  NavigationContainer,
  PageTitleContextProvider,
  RoutesContainer,
  ServiceProvider
} from './common';
import { stylesOverride } from './styles-override';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { EnvProvider } from './common';
import {} from './modules';
import { AuthenticationContextProvider } from './common/authentication-context-provider/AuthenticationContextProvider';

function App() {
  const drawerWidth = 256;
  const styles = {
    content: {
      flexGrow: 1,
      padding: 3
    }
  } as const;

  return (
    <ErrorBoundary>
      <EnvProvider
        config={{
          dateFormatString: 'yyyy-MM-dd',
          dateTimeFormatString: 'yyyy-MM-dd HH:mm'
        }}
      >
        <ApplicationInsightsContext>
          <AppInsightsErrorBoundary>
            <AuthenticationContextProvider>
              <ServiceProvider>
                <ThemeProvider theme={createTheme(stylesOverride)}>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    autoHideDuration={3000}
                  >
                    <ConfirmProvider>
                      <PageTitleContextProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Router>
                            <Box sx={{ display: 'flex' }}>
                              <CssBaseline />
                              <NavigationContainer drawerWidth={drawerWidth} />
                              <Box component="main" sx={styles.content}>
                                <NavigationOffset />
                                <RoutesContainer />
                                <Outlet />
                              </Box>
                            </Box>
                          </Router>
                        </LocalizationProvider>
                      </PageTitleContextProvider>
                    </ConfirmProvider>
                  </SnackbarProvider>
                </ThemeProvider>
              </ServiceProvider>
            </AuthenticationContextProvider>
          </AppInsightsErrorBoundary>
        </ApplicationInsightsContext>
      </EnvProvider>
    </ErrorBoundary>
  );
}

export default App;
