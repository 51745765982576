import { styled, Tooltip } from '@mui/material';
import { FC } from 'react';

interface ITooltipWrapper {
  displayTooltip?: boolean;
  feedbackMessage?: string;
}

export const TooltipWrapper: FC<ITooltipWrapper> = ({
  displayTooltip = false,
  feedbackMessage = '',
  children
}) => {
  if (feedbackMessage === '' || !displayTooltip) {
    return <>{children}</>;
  }

  const Div = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  });

  return (
    <Tooltip title={feedbackMessage} placement="top" arrow>
      <Div>{children}</Div>
    </Tooltip>
  );
};
