import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormState
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton } from 'src/common';
import { useUserService } from 'src/services';
import { Role, User } from 'src/types';

export const CreateUser = () => {
  const { userId } = useParams();
  const { getUser, getRolesList, createOrEditUser } = useUserService();
  const { enqueueSnackbar } = useSnackbar();

  const [currentEmail, setCurrentEmail] = useState('');
  const [rolesList, setRolesList] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const userInput = 'userInput';

  const methods = useForm<User>({
    mode: 'onChange',
    defaultValues: {
      emailAddress: '',
      firstName: '',
      lastName: '',
      active: true,
      rolesDescription: '',
      roleIds: []
    }
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    watch,
    clearErrors,
    getValues
  } = methods;

  const { isDirty, isValid } = useFormState({ control });
  const watchEmail = watch('emailAddress');

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        const user: User = await getUser(Number(userId));
        reset(user);
      }
    };
    void fetchUser();
  }, [userId, reset, getUser]);

  useEffect(() => {
    (async () => {
      const roles: Role[] = await getRolesList();

      setRolesList(roles);
    })();
  }, [getRolesList]);

  const onSubmit: SubmitHandler<User> = async (data) => {
    try {
      setIsLoading(true);
      const response = await createOrEditUser(data);

      if (!response.success) {
        const errorsList = response?.errors ?? {};

        setCurrentEmail(getValues('emailAddress'));
        setError('emailAddress', {
          type: userInput,
          message: errorsList[0]
        });

        reset(
          {},
          {
            keepErrors: true,
            keepValues: true,
            keepIsValid: true,
            keepDirty: true
          }
        );
        setIsLoading(false);
        return;
      }

      enqueueSnackbar(`${!userId ? 'Added' : 'Updated'} user`, {
        variant: 'success'
      });
      navigate('/users', { replace: true });
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as AxiosError | any;
      const { response } = err;
      const errorsList = response?.data.errors ?? {};

      if (err.message === 'Network Error') {
        enqueueSnackbar('Network Error', {
          variant: 'error'
        });
      }

      if ('EmailAddress' in errorsList) {
        setCurrentEmail(getValues('emailAddress'));
        setError('emailAddress', {
          type: userInput,
          message: errorsList['EmailAddress'][0]
        });
      }

      if (!('EmailAddress' in errorsList)) {
        const message: string = response?.data.title;
        enqueueSnackbar(message || 'There was an error saving the user', {
          variant: 'error'
        });
      }
      reset(
        {},
        {
          keepErrors: true,
          keepValues: true,
          keepIsValid: true,
          keepDirty: true
        }
      );
      setIsLoading(false);
    }
  };

  const onBlur = () => {
    if (watchEmail === currentEmail && watchEmail !== '') {
      setError('emailAddress', {
        type: userInput,
        message: 'A user with this email already exists.'
      });
    } else {
      clearErrors('emailAddress');
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onBlur={onBlur}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box>
            <Box display="flex" justifyContent="space-between">
              <BackButton to="/users" />
              <LoadingButton
                disabled={!isDirty || !isValid}
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                {!userId ? 'Save User' : 'Update User'}
              </LoadingButton>
            </Box>
            <Box mt={3}>
              {!userId ? (
                <Typography variant="h5">Add User</Typography>
              ) : (
                <Typography variant="h5">Edit User</Typography>
              )}
            </Box>

            <Grid container spacing={2} mt={1} alignItems="center">
              <Grid item xs={5}>
                <Controller
                  name="firstName"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={
                        errors.firstName?.type === 'unique' ||
                        errors.firstName?.type === 'required' ||
                        errors.firstName?.type === userInput
                      }
                      helperText={
                        errors.firstName?.type === 'required'
                          ? 'First name is required.'
                          : '' || errors.firstName?.type === userInput
                          ? errors.firstName?.message
                          : ''
                      }
                      id="name"
                      label="First Name"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={
                        errors.lastName?.type === 'unique' ||
                        errors.lastName?.type === 'required' ||
                        errors.lastName?.type === userInput
                      }
                      helperText={
                        errors.lastName?.type === 'required'
                          ? 'Last name is required.'
                          : '' || errors.lastName?.type === userInput
                          ? errors.lastName?.message
                          : ''
                      }
                      id="lastName"
                      label="Last Name"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} alignItems="center">
              <Grid item xs={5}>
                <Controller
                  name="emailAddress"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={
                        errors.emailAddress?.type === 'unique' ||
                        errors.emailAddress?.type === 'required' ||
                        errors.emailAddress?.type === userInput
                      }
                      helperText={
                        errors.emailAddress?.type === 'required'
                          ? 'Email is required.'
                          : '' || errors.emailAddress?.type === userInput
                          ? errors.emailAddress?.message
                          : ''
                      }
                      id="emailAddress"
                      label="Email Address"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name="roleIds"
                  control={control}
                  render={({
                    field: { name, value, onChange, ...otherOptions }
                  }) => (
                    <FormControl>
                      <InputLabel id="role-filter-select">Role</InputLabel>
                      <Select
                        labelId="role-filter-select"
                        id="role-simple-select-filled"
                        multiple
                        value={value}
                        onChange={onChange}
                        name={name}
                        renderValue={(selected) => (
                          <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.map((roleId) => (
                              <Chip
                                key={roleId}
                                label={
                                  rolesList.find((e) => e.roleId === roleId)
                                    ?.name
                                }
                                onDelete={() =>
                                  onChange(
                                    value.filter((item) => item !== roleId)
                                  )
                                }
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                              />
                            ))}
                          </Stack>
                        )}
                        {...otherOptions}
                      >
                        {rolesList.map((role) => (
                          <MenuItem key={role.roleId} value={role.roleId}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs>
                <Controller
                  name="active"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box display="flex">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={value}
                            onChange={(event) => onChange(event.target.checked)}
                            color="secondary"
                          />
                        }
                        label="Active"
                      />
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormProvider>
    </Paper>
  );
};
