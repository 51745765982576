import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { HubConnectionState } from '@microsoft/signalr';
import { Button } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useServiceProvider } from 'src/common';
import { v4 as uuid } from 'uuid';

export const useSignalRSoundNotification = () => {
  const [notificationSound, setNotificationSound] = useState<string | null>(
    null
  );
  const { hubConnection } = useServiceProvider();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    const action = (snackbarId: SnackbarKey) => (
      <>
        <Button
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          Dismiss
        </Button>
      </>
    );
    const createConnection = async () => {
      const waitUntilConnected = () => {
        return new Promise((resolve, reject) => {
          const msMax = 5000;
          const msInc = 50;

          let ms = 0;

          const idInterval = setInterval(() => {
            if (hubConnection.state === HubConnectionState.Connected) {
              clearInterval(idInterval);
              resolve(hubConnection);
            }

            ms += msInc;

            if (ms >= msMax) {
              clearInterval(idInterval);
              reject(hubConnection);
            }
          }, msInc);
        });
      };

      try {
        await waitUntilConnected();

        hubConnection.on('NewPendingProduct', () => {
          setNotificationSound(uuid());
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        appInsights.trackException({
          exception: err as Error,
          severityLevel: SeverityLevel.Error,
          properties: {
            ...(err as Error)
          }
        });
        enqueueSnackbar(
          'Live updates have been interrupted, please refresh your page to reconnect',
          {
            action,
            variant: 'warning',
            persist: true,
            preventDuplicate: true
          }
        );
      }
    };

    // eslint-disable-next-line no-console
    createConnection().catch((err) => console.error(err));

    return () => {
      hubConnection.off('NewPendingProduct');
    };
  }, [appInsights, closeSnackbar, enqueueSnackbar, hubConnection]);

  return { notificationSound };
};
