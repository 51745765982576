import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { Request, PaginatedResponse, SalesCycle } from 'src/types';

export const useSalesCycleService = () => {
  const { auctionsApi } = useServiceProvider();

  const getSalesCyclesList = useCallback(
    async (queryParams: Request): Promise<PaginatedResponse<SalesCycle>> => {
      const response: AxiosResponse<PaginatedResponse<SalesCycle>> =
        await auctionsApi.get('/SalesCycles', {
          params: queryParams
        });
      return response.data;
    },
    [auctionsApi]
  );

  const createSalesCycle = useCallback(
    async (salesCycle: Record<string, unknown>): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.post(
        '/SalesCycles',
        salesCycle,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const updateSalesCycle = useCallback(
    async (salesCycle: SalesCycle): Promise<number> => {
      const response: AxiosResponse<number> = await auctionsApi.put(
        `/SalesCycles?id=${salesCycle.salesCycleId}`,
        salesCycle,
        {
          headers: {
            accept: 'text/plain',
            contentType: 'application/json'
          }
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  const getSalesCycle = useCallback(
    async (salesCycleId: number) => {
      const response: AxiosResponse<SalesCycle> = await auctionsApi.get(
        `/SalesCycles/${salesCycleId}`
      );
      return response.data;
    },
    [auctionsApi]
  );

  return {
    getSalesCyclesList,
    createSalesCycle,
    updateSalesCycle,
    getSalesCycle
  };
};
