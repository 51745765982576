import { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useServiceProvider } from 'src/common';
import { LotUploadResponse, PaginatedResponse, Request } from 'src/types';
import { LotUpload } from 'src/types/models/LotUpload.type';

export const useLotUploadService = () => {
  const { auctionsApi } = useServiceProvider();
  const uploadBatchOfLotImages = useCallback(
    async (salesCycleId: number, images: LotUpload[]) => {
      try {
        return await auctionsApi.post('/LotImages', {
          images: images,
          salesCycleId
        });
      } catch (error) {
        const { response } = error as AxiosError;
        return response;
      }
    },
    [auctionsApi]
  );

  const getPaginatedLotImages = useCallback(
    async ({
      page,
      pageSize,
      filters
    }: Request): Promise<PaginatedResponse<LotUploadResponse>> => {
      const response: AxiosResponse<PaginatedResponse<LotUploadResponse>> =
        await auctionsApi.get('/LotImages', {
          params: {
            page,
            pageSize,
            salesCycleId: get(filters, 'salesCycleId', -1)
          }
        });
      return response.data;
    },
    [auctionsApi]
  );

  const getLotImageById = useCallback(
    async (lotImageId: number): Promise<Blob> => {
      const response: AxiosResponse<Blob> = await auctionsApi.get(
        '/LotImages/image',
        {
          params: {
            lotImageId
          },
          responseType: 'blob'
        }
      );
      return response.data;
    },
    [auctionsApi]
  );

  return { uploadBatchOfLotImages, getPaginatedLotImages, getLotImageById };
};
